import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './App.css';
import EmotionClassification from './pages/EmotionClassification';
import Counting from './pages/Counting';
import DemoVideo from './pages/DemoVideo';
import Demo from './pages/Demo';

import ResponsiveDrawer from './components/header';


import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
library.add(faInfoCircle);
 

function App() {
	return (
		<Router>
			<div className="App">
				<ResponsiveDrawer/>
				<Route exact path="/EmotionClassification" component={EmotionClassification} />
				<Route exact path="/Counting" component={Counting} />                    
				<Route exact path="/DemoVideo" component={DemoVideo} />          
				<Route exact path="/Demo" component={Demo} />  
			</div>
        </Router>
	);
}

export default App;
