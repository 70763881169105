import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet';
// import logo from './logo.svg';
// import './App.css';
const API_HOST = process.env.REACT_APP_API_HOST
const path = "/api/coral_data/"
const url = API_HOST + path

class EmotionClassification extends React.Component{
	
	constructor(props){
		super(props)
		this.state = {
			start:false,
			idname:"",
			dataList:[],
			nextURL:null,
			intervalID:null
		}
	}


	start = async()=>{
		if(!this.state.idname){
			alert("IDをいれてください")
			return
		}
		this.setState({start:true})
		const response = await axios.get(url+`?idname=${this.state.idname}`)
		let nextURL = response.data.next
		let dataList = response.data.results
		this.setState({nextURL, dataList})
		this.setIntervalToFetchNewData()
	}

	setIntervalToFetchNewData=()=>{
		this.intervalID = setInterval(
			() => this.getNewData(),
			1000
		  );
	}

	stopInterval=()=>{
		this.setState({start:false})
		clearInterval(this.intervalID);
	}

	getOldData=async()=>{
		if(!this.state.nextURL){
			alert("これ以上データはありません")
			return
		}
		const response = await axios.get(this.state.nextURL)
		let nextURL = response.data.next
		let addedDataList = response.data.results
		//dataを結合
		let dataList = this.state.dataList.concat(addedDataList)

		this.setState({nextURL,dataList})
	}

	getNewData=async()=>{
		let pk_list = this.state.dataList.map(data=>data.pk)
		let params = {"pk_list":pk_list}
		const response = await axios.get(url+`latest?idname=${this.state.idname}`, {params:params})
		console.log("response", response.data)

		let newDataList = [response.data]
		//dataを結合
		let dataList = newDataList.concat(this.state.dataList)
		this.setState({dataList})
	}

	// deleteData=async()=>{
	// 	// if(!confirm("サーバー上の全ての推論結果のデータが削除されます。\nよろしいですか？")){
	// 	// 	return
	// 	// }
	// 	const response = await axios.delete(url+`?idname=${this.state.idname}`)
	// 	this.setState({"dataList":[]})
	// }

	renderTable(){
		return(
			<>
			<table style={{margin:"auto"}}>
				<tbody>
						<tr>
							<th>日時</th>
							<th>Surprise</th>
							<th>Happy</th>
							<th>Sad</th>
							<th>Neutral</th>
							<th>Total</th>
						</tr>
						{this.renderTableContent()}   
				</tbody>
    		</table>
			<button onClick={this.getOldData}>もっと取得</button>
			</>
		)
	}

	renderTableContent(){
		return(
			this.state.dataList.map(data=>
				<tr　key={data.pk}>
					<td>
						{ new Date(data.datetime).toLocaleString("ja-JP") }
					</td>                
					<td>
						{ data.surprise }
					</td>
					<td>
						{ data.happy }
					</td>
					<td>
						{ data.sad }
					</td>
					<td>
						{ data.neutral }
					</td>
					<td>
						{ data.total }
					</td>
				</tr> 
			)
		)
	}

  render(){  
    return (
        <div className="content">
			<Helmet title="EmotionRecognition"/>
			{/* <button id="delete" onClick={()=>{this.getNewData()}}>データ削除</button> */}
			{!this.state.start?			
				<button id="start" onClick={()=>{this.start()}}>データ取得スタート</button>
					:
				<button id="stop" onClick={()=>{this.stopInterval()}}>ストップ</button>	
			}
			ID: <input type="text" name="idname" value={this.state.idname} 
				onChange={(e)=>this.setState({idname:e.target.value})}/>
			{this.renderTable()}
        </div>
    );
  }
}

export default EmotionClassification;