import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import VisibleItemList from '../containers/VisibleItemList'

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
}));

function ResponsiveDrawer() {

    const Categories = ["EmotionClassification", "Counting", "DemoVideo", "Demo"]
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }

    const drawer = (
        <div>
            <List>
                {Categories.map((link,index)=>
                    <ListItem button key={index}>
                        <Link to={`/${link}`}>{`${link}`}</Link>        
                    </ListItem>)}
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar, "header"}>
                <img className="header_logo" src="/logo192.png"/>
                <Toolbar className="header_bar">
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>
                    {/* <Typography variant="h6" noWrap>
                        Responsive drawer
                    </Typography> */}                    
                    <Hidden xsDown implementation="css">
                        <nav className="header_navigation">
                            {Categories.map((link,index)=>
                                <Link to={`/${link}`}>{`${link}`}</Link>        
                            )}
                        </nav>
                    </Hidden>
                </Toolbar>
            </AppBar>
        
            <nav className={classes.drawer}>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                        <CloseIcon/>
                        </IconButton>
                        {drawer}
                    </Drawer>
                </Hidden>
                {/* <Hidden xsDown implementation="css">
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                        paper: classes.drawerPaper,
                        }}>
                        <div className={classes.toolbar} />
                        {drawer}
                    </Drawer>  
                </Hidden> */}
            </nav>
            <div className={classes.content}>
                <div className={classes.toolbar} />
                {/* <VisibleItemList/> */}
            </div>
        </div>
    );
}
ResponsiveDrawer.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
};
export default ResponsiveDrawer;
// export default Header;

// const Header = () =>{

//     return(
//             <header className="App-header">
//                 <img className="header_logo" src="/logo192.png"/>
//                 <nav className="header_navigation">
//                     <Link to="/EmotionClassification">EmotionClassification </Link>        
//                     <Link to="/StyleTransfer">StyleTransfer </Link>        
//                     <Link to="/Counting">Counting </Link>        
//                     <Link to="/DemoVideo">DemoVideo </Link>        
//                     <Link to="/Demo">Demo </Link>        
//                 </nav>        
//             </header>)
// }
// export default Header