import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import info from '../DataSrc';



const start = 9
const end = 17
const timeLength = end - start
const timeArray = [...Array(timeLength*2).keys()].map(i=>i/2+start)
const typeArray = ["移動", "営業先会議", "リモートワーク", "社内デスクワーク", "社内ミーティング"]
const blockWidth = 40
const blockHeight = 32
const eventImageSize = 192


// 無駄が多いアルゴリズムなのであとで変える
// 今は全部のスケジュール開けて、startとendの間にblockのtimeが入ってないか確認してる
// まずtype確認から入る
function isThisType(type, time, scheduleArray){
	let booleanArray = scheduleArray.map(schedule=>{
		let start = Number(schedule.time.split('-')[0])
		let end = Number(schedule.time.split('-')[1])
		if(schedule.plan===type&&start<=time&&time<end){
			return true
		}
		else{
			return false
		}
	})
	return booleanArray.some(element=>element===true)
}

//そのイベントのメンバーを取り出す
function extractMemberList(type, time, scheduleArray){
	let memberList
	scheduleArray.forEach(schedule=>{
		let start = Number(schedule.time.split('-')[0])
		let end = Number(schedule.time.split('-')[1])
		if(schedule.plan===type&&start<=time&&time<end&&schedule.memberList){
			memberList = schedule.memberList
		}
	})
	return memberList
}

//そのイベントの画像を取り出す
function extractImage(type, time, scheduleArray){
	let image
	scheduleArray.forEach(schedule=>{
		let start = Number(schedule.time.split('-')[0])
		let end = Number(schedule.time.split('-')[1])
		if(schedule.plan===type&&start<=time&&time<end&&schedule.image){
			image = schedule.image
		}
	})
	return image
}

class TimeBlock extends React.Component{

	constructor(props){
		super(props)
		this.state = {
			eventName:props.eventName,
			isActive:props.isActive,
			memberList:props.memberList,
			eventImage:props.eventImage,
			infoVisible:false,
			eventImageVisible:false,
			// hoverX:null,
			hoverY:null,
		}
	}

	onClick=(e)=>{
		if(!this.state.isActive||!this.state.eventName==="社内ミーティング"){
			return
		}
		this.props.onClick(this.state.eventName, this.state.memberList)
	}

	onMouseEnter=(e)=>{
		if(this.state.eventName==="社内ミーティング"&&this.state.memberList){
			this.setState({infoVisible:true})
		}
		// else if(this.state.eventName==="移動"&&this.state.eventImage){
		if(this.state.eventImage){
			this.setState({eventImageVisible:true, hoverY:e.clientY})
			// this.props.onMouseEnter(this.state.eventName, this.state.eventImage, e.pageX, e.pageY)
		}
	}
	onMouseLeave=()=>{
		this.setState({infoVisible:false, eventImageVisible:false})
	}

	renderEventImage=()=>{
		let translate = {transform: window.innerHeight/3<this.state.hoverY?`translate3d(20px,-${eventImageSize+10}px,0)`:"translate3d(20px,10px,0)"}
		return(
			// <div style={{ top:this.state.hoverY, left:this.state.hoverX }}>
			<div className="event_image_wrapper" style={{ top:this.state.hoverY, ...translate}}>
				<img className="event_image" style={{ width:eventImageSize, height:eventImageSize, }} src={this.state.eventImage} />
			</div>
		)
	}

	renderIcon(){
		return <FontAwesomeIcon icon="info-circle" style={{ color:"#fff" }}/>
	}

	render(){
		return(
			<td className="time_block" 
				onClick={(e)=>this.onClick(e)} 
				onMouseEnter={(e)=>this.onMouseEnter(e)}
				onMouseLeave={()=>this.onMouseLeave()}
				style={this.state.isActive?timeBlockActive:timeBlock}>
				{this.state.infoVisible&&this.renderIcon()}
				{this.state.eventImageVisible&&this.renderEventImage()}
			</td>
		)
	}
}

class Demo extends React.Component{
	
	constructor(props){
		super(props)
		this.state = {
			imageBoxVisible:false,
			imageVisible:false,
			image:null,
			memberList:[],
			clickEventName:"",
			hoverEventName:"",
		}
	}

	// showImage=(eventName, image, x, y)=>{
	// 	// if(eventName!="移動"){return}
	// 	this.setState({hoverEventName:eventName, imageVisible:true, image })
	// }

	showImageBox=(eventName, memberList)=>{
		this.setState({clickEventName:eventName, imageBoxVisible:true, memberList})
	}
	closeImageBox=()=>{
		this.setState({clickEventName:"",imageBoxVisible:false, memberList:[]})
	}

	//typeごとの関数、その時間ないのplanと合致しているか
	renderTimeBlock(type, scheduleArray){
		return(
			timeArray.map(time=> 
				<TimeBlock 
					onClick={this.showImageBox}
					// onMouseEnter={this.showImage}
					eventName={type}
					eventImage={extractImage(type, time, scheduleArray)}
					memberList={extractMemberList(type, time, scheduleArray)}
					isActive={isThisType(type, time, scheduleArray)}/>)
		)
	}

	renderSchedule(schedule){
		return(
			typeArray.map(type=>
				<tr style={{ textAlign:"right", marginBottom:8}}>
					<th style={{ paddingRight:8 }}>{type}</th>
					{this.renderTimeBlock(type, schedule)}
				</tr>)
			)
	}

	renderTimeLabel(){
		return(
			<tr>
				<th></th>
				{timeArray.map(time=><td className="time_label">{Number.isInteger(time)?time:""}</td>)}
				<td className="time_label">{end}</td>
			</tr>)
	}

	renderStats(statsArray){
		return(
			<div className="stats_wrapper" style={statsWrapper}>
				<p style={{ fontWeight:600, marginBottom:8 }}>Stats</p>
				<ul className="stats_list">
					{statsArray.map(stats=>{
						return(
							<li>
								<span>{stats.type}</span> : <span>{stats.value}</span>
							</li>)
					})}
				</ul>
			</div>
		)
	}

	renderPersonSchedule(){
		return(
			info.map(person=>
				<div style={personRow}>
					<div style={{ textAlign:"left" }}>
						<span style={{ textDecoration: "underline" }}>ID:{person.ID}</span>
					</div>
					<div className="schedule_table_wrapper">
						<table className="schedule_table" style={scheduleTable}>
							<tbody>
								{this.renderTimeLabel()}
								{this.renderSchedule(person.schedule)}
							</tbody>
						</table>
						<div style={{}}>
							{this.renderStats(person.stats)}
						</div>
					</div>
				</div>
			)
		)
	}

	renderImageBox(){
		if(!["社内ミーティング"].includes(this.state.clickEventName))return
		return(
			<div className="image_box_modal">
				<div className="image_box_wrapper">
					<p style={{ textAlign:"left", color:"#fff", margin:8 }}><span className="close_modal_cross" onClick={()=>this.closeImageBox()}>閉じる</span></p>					
					<div className="image_box">
						<p style={{marginTop:8,}}>{this.state.clickEventName}</p>
						<ul class="member_list" style={memberList}>
							{this.state.memberList.map(image=>
								<li style={{width:150, marginTop:8 }}>
									<img src={image.image} style={imageStyle}/>
									<p style={{ fontSize:14, paddingTop:4, paddingBottom:4 }}>{image.name}</p>
								</li>)}
						</ul>
					</div>
				</div>
			</div>)
	}

	// renderImage=()=>{
	// }

	// renderInfo=()=>{
	// 	if(this.state.clickEventName==="社内ミーティング"){
	// 		this.renderImageBox()
	// 	}
	// 	// else if(this.state.clickEventName=="移動"){
	// 	// 	this.renderImage()
	// 	// }
	// 	// else{
	// 	// 	return
	// 	// }
	// }


  render(){  
    return (
        <div className="content">
			{this.state.imageBoxVisible&&this.renderImageBox()}
			<div className="mock_wrapper">
				<div className="date">
					<span style={{ fontSize:20 }}>2019年10/25(金)</span>
				</div>
				{this.renderPersonSchedule()}
			</div>
        </div>
    );
  }
}

const personRow = {
	marginBottom:24
}
const scheduleTable = {
	// borderCollapse:"collapse",
	borderSpacing: "0px 0px",
	// marginLeft:80
}
const timeBlock = {
	// maxWidth:blockWidth,
	// minWidth:blockWidth,
	// minWidth:20,
	height:blockHeight,
	borderRight:"1px dashed rgba(0,0,0,0.12)",
	boxSizing:"border-box",
	zIndex: 2,
}

const timeBlockActive = {
	// maxWidth:blockWidth,
	// minWidth:blockWidth,
	// minWidth:20,
	height:blockHeight,
	backgroundColor:"rgb(108,155,210)", 
	borderRight:"1px dashed rgba(0,0,0,0.12)",
	boxSizing:"border-box",
	zIndex: 2,
}

const statsWrapper = {
	textAlign:"left"
}

const imageBox = {
	padding:8,
	backgroundColor:"#fff",
	borderRadius: 5,
	border:"1px solid rgba(0,0,0,0.54)"
}

const memberList = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	justifyContent:"space-around",
	width:498,
	margin:"auto",
	padding:0
}
const imageStyle = {
	objectFit:"cover",
	width:150,
	height:150,
	// margin:16,
}

export default Demo;

// 画面外に出ないように