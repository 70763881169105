import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
// import logo from './logo.svg';
// import './App.css';

const API_HOST = process.env.REACT_APP_API_HOST
const MEDIA_PREFIX = process.env.REACT_APP_MEDIA_PREFIX

// const API_HOST = "https://demo.bestat-data.com"

class DemoVideo extends React.Component{
	
	constructor(props){
		super(props)
		this.state = {
			videoSrc:"",
			username:"",
			password:""
		}		
	}

	async componentDidMount(){
		// const data = { 'bar': 123 };
		// const encoded = btoa("jvckenwood:jvckenwood");
		// console.log("encoded", encoded)

		// const client = axios.create({
		// 	auth: {
		// 	  username: "jvckenwood",  //This could be your email
		// 	  password: "jvckenwood"
		// 	},
		// 	headers: {
		// 	  "Content-Type": "application/json"
		// 	}
		//   });

		// const mailChimpRes = await client({url:`${API_HOST}/demo_video`,method:"get"});
		// console.log("aaaa", mailChimpRes)
		
		// fetch(`${API_HOST}/demo_video`,  {
		// 	// mode: 'cros',
		// 	headers: 
		// 		{
		// 			// 'X-Requested-With':'XMLHttpRequest',
		// 			// 'content-type': 'application/x-www-form-urlencoded',
		// 			// 'Access-Control-Allow-Methods':"GET",
		// 			'Authorization':`Basic ${encoded}`	,
		// 			'Access-Control-Allow-Origin':"*"			
		// 		},

		//   }).then((res)=>console.log("res",res))
		// let response = await axios.get(
		// 	`${API_HOST}/demo_video`, 
		// 	{},
		// 	{
		// 		headers: 
		// 		{
		// 			// 'X-Requested-With':'XMLHttpRequest',
		// 			// 'content-type': 'application/x-www-form-urlencoded',
		// 			// 'Access-Control-Allow-Methods':"GET",
		// 			'Authorization':`Basic ${encoded}`	,
		// 			'Access-Control-Allow-Origin':"*"			
		// 		},
		// 		mode: 'cros',
		// 		// auth: {
		// 		// 	username:"jvckenwood",
		// 		// 	password:"jvckenwood",
		// 		// },
		// 		data: {},
		// 		withCredentials: "include",
		// 	}
		// )
		// let res = await axios.get("https://www.google.com")
		// console.log("g", res)
		// let response = await axios({
		// 	method:"get",
		// 	url:`${API_HOST}/demo_video`, 
		// 	auth:{
		// 					username:"jvckenwood",
		// 					password:"jvckenwood",
		// 				},
		// 	withCredentials: "include",
			
		// })
		// console.log("respo", response.data)

	}

	getVideoSrc = async ()=>{
			let params = {
				username:this.state.username,
				password:this.state.password}
			const response = await axios.get(`${API_HOST}/api/demo_video/`, {params})
			console.log("media", MEDIA_PREFIX)
			let videoSrc =  MEDIA_PREFIX + response.data.file
			this.setState({videoSrc})
	}
	


	render(){  
		return (
			<div className="content">
				<input value={this.state.username} type="text" placeholder="username"
					onChange={(e)=>this.setState({username:e.target.value})} />
				<input value={this.state.password} type="text" placeholder="password"
					onChange={(e)=>this.setState({password:e.target.value})} />
				<button onClick={()=>this.getVideoSrc()}>OK</button>
				<video controls loop type="video/mp4"
					style={{display:"block",
							margin:"auto",
							transform: "inherit",
							maxWidth: "100%",
							maxHeight: "100%"}}
					src={this.state.videoSrc}>
				</video>
			</div>
		);
  }
}

export default DemoVideo;