import React from "react";
import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST
const url = API_HOST + '/api/'

class CountingMetaFetch extends React.Component{
	
	constructor(props){
		super(props)
		this.state = {
			idname:"",			
			isFetchingMetaData:false,
			intervalID:null,
			metaDataList:null,
		}
		this.startFetching()
	}

	componentWillUnmount(){
		this.stopFetching()
	}

	startFetching(){
		this.state.intervalID = setInterval(()=>
			this.fetchMetaData(), 1000
		);
	}

	stopFetching(){
		this.setState({isFetchingMetaData:false})
		clearInterval(this.state.intervalID)
	}

	async fetchMetaData(){
		if(this.state.isFetchingMetaData){
			return
		}
		if(!this.state.idname){
			return
		}
		let params = {"idname":this.state.idname}
		let response = await axios.get(url+'count_meta_group/', {params})
		let metaDataList = response.data.results[0].count_meta_list
		this.setState({"metaDataList":metaDataList})
	}

  	render(){  
		return (
			<>
				<input
					value={this.state.idname} 
					onChange={(e)=>this.setState({"idname":e.target.value})} type="text"/>
				{this.state.metaDataList&&
				<table style={{margin:"auto"}}>
					<tbody>
						{this.state.metaDataList.map((meta, index)=>
							<tr key={index}>
								<th>{meta.class_name}</th>
								<td>{meta.count}</td>
						</tr>)}
					</tbody>
				</table>}
			</>
		);
  	}
}

export default CountingMetaFetch;