import React from "react";
import axios from 'axios';
import VoiceRecording from './CountingVoiceRecording';
var moment = require('moment');

const API_HOST = process.env.REACT_APP_API_HOST
const url = API_HOST + '/api/'


const CanvasImage = {

	get_picture_from_canvas: function(){
		return this.create_blob_from_url(this.get_canvas_url())
	},
	
	// canvasの画像のURLを得る
	get_canvas_url: function(){
		let canvas = document.getElementById('canvas')
		let canvas_image_url = canvas.toDataURL("image/jpeg")
		return canvas_image_url
	},
	
	create_blob_from_url: function(url){
		let blob = this.Base64toBlob(url)  
		blob.lastModifiedDate = new Date() ;
		blob.name = Date.now() + "picture.jpg";
		return blob
	},
							
	Base64toBlob: function(base64){
		let tmp = base64.split(',');
		let data = atob(tmp[1]);
		let mime = tmp[0].split(':')[1].split(';')[0];
		let buf = new Uint8Array(data.length);
		for (let i = 0; i < data.length; i++) {
			buf[i] = data.charCodeAt(i);
		}
		let blob = new Blob([buf], { type: mime });
		return blob;
	}
}



class CountingSendImage extends React.Component{
	
	constructor(props){
		super(props)
		let finishRecordingEvent = new Event('finishRecording');
		this.state = {
			isVideoLoading: false,			
			idname:"",
			isSetToSendingImage:false,
			count:"",
			watchCordinatesID:null,
			lon:null,
			lat:null,
			startRecordingVoice:false,
			voiceRecord:null,
			finishRecordingEvent:finishRecordingEvent,
			sendImageInterval:3,
		}
	}

	init(){
		this.videoTag = React.createRef();
		this.canvas = React.createRef();
		this.tick = this.tick.bind(this);
		this.getCordinatesRegularly()
		// this.setState({isVideoLoading:true})
		this.startStreamVideo()
	}

	componentDidMount(){
		this.init()
	}

	componentWillUnmount(){
		navigator.geolocation.clearWatch( this.state.watchCordinatesID );
		this.setState({watchCordinatesID:null})
	}


	getCordinatesRegularly(){
		let watchCordinatesID = navigator.geolocation.watchPosition(
			this.setCordinates,
			function(e) { console.log(e.message); }, 
			{"enableHighAccuracy": true, "timeout": 20000, "maximumAge": 2000}
		);
		this.setState({watchCordinatesID})
	}

	setCordinates=(position)=>{
		this.setState({lon:position.coords.longitude, lat:position.coords.latitude})
	}

	startStreamVideo(){
		navigator.mediaDevices
		.getUserMedia({ video: { facingMode: "environment" } })
		.then(stream => {
			const video = this.videoTag.current;
			video.srcObject = stream;
			video.setAttribute("playsinline", true);
			video.play();
			requestAnimationFrame(this.tick);
		});
	}	

	tick() {
		const video = this.videoTag.current;
		// this.setState({startRecordingVoice:true})
		const checkVideoState = setInterval(() => {
			if (video.readyState === video.HAVE_ENOUGH_DATA && this.canvas.current && this.state.isSetToSendingImage) {
				clearInterval(checkVideoState);
				this.setState({ isVideoLoading: false });

				const canvasElement = this.canvas.current;
				const canvas = canvasElement.getContext("2d");

				canvasElement.height = video.videoHeight;
				canvasElement.width = video.videoWidth;
				canvas.drawImage(
					video,
					0,
					0,
					canvasElement.width,
					canvasElement.height
				);
				
				requestAnimationFrame(this.tick);
				this.sendImage();				
				}
    	}, this.state.sendImageInterval*1000);
	}


	startSending=()=>{
		if(!this.state.idname){
			alert("IDを入力してください")
			return
		}
		this.setState({isSetToSendingImage:true, startRecordingVoice:true})
	}

	setStartRecording=async()=>{
		await this.setState({startRecordingVoice:false})
	}

	waitForFinishRecording(){
		return new Promise(resolve=>{
			const listner = resolve
			var audio = document.getElementById('audio');
			audio.addEventListener('finishRecording', listner)
		})
	}
	
	sendImage=async()=>{
		console.log("sendImage始め")
		await this.setStartRecording()
		await this.waitForFinishRecording()
		console.log("event後")
		// this.setState({startRecordingVoice:false})
		if(!this.state.idname){
			console.log("no idname")
			return
		}
		if(!this.state.isSetToSendingImage){
			console.log("not set to send image")
			return
		}
		if(!this.state.voiceRecord){
			console.log("no voice record")
			return
		}
		console.log("send　直前", this.state.voiceRecord.name)
		let picture = CanvasImage.get_picture_from_canvas()
		let params = new FormData();
		params.append("file", picture, picture.name)
		params.append("voice_record", this.state.voiceRecord, this.state.voiceRecord.name)
		params.append("idname", this.state.idname)
		params.append("datetime", moment().format())
		params.append("longitude", this.state.lon?this.state.lon:0)
		params.append("latitude", this.state.lat?this.state.lat:0)
		let response = await axios.post(url+'counting/browser_image_count', params)
		this.setState({count:response.data.count, startRecordingVoice:true, voiceRecord:null})
	}

	waitForVoiceAvailable=()=>{

	}

	takeVoiceRecord=async(voiceRecord)=>{
		console.log("take", voiceRecord)
		this.setState({voiceRecord})
	}


  	render(){  		
		// console.log("render")
		let isVideoLoading = this.state.isVideoLoading;
		return (
				<>
					<br></br>
					ID<input
						value={this.state.idname} 
						onChange={(e)=>this.setState({idname:e.target.value})} type="text"/>
					送信間隔<input
						value={this.state.sendImageInterval} 
						onChange={(e)=>this.setState({sendImageInterval:e.target.value})} style={{width:3+'rem'}} type="number"/>秒						
					<div>
						<span id="person_count"></span>				
					</div>
					<div>
						{this.state.isSetToSendingImage?
							<button onClick={()=>{this.setState({isSetToSendingImage:false})}}>
								Stop
							</button>
								:
							<button onClick={()=>this.startSending()}>
								Start
							</button>
						}
						<p>
							{this.state.count + "人"}
						</p>
						<div>
							<div>
								<video ref={this.videoTag} id="player" className="video" autoPlay playsInline></video>
							</div>
							<div>
								{!isVideoLoading && <canvas id="canvas" ref={this.canvas} />}
								{isVideoLoading && <p>Please wait while we load the video stream.</p>}	
							</div>
						</div>
					</div>					
					<VoiceRecording
						startRecordingVoice={this.state.startRecordingVoice}
						giveVoiceRecord={this.takeVoiceRecord}
						finishRecordingEvent={this.state.finishRecordingEvent}/>
				</>)
  	}
}

export default CountingSendImage;