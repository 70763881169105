import React from "react";
import CountingAuthentication from '../components/CountingAuthentication';
import CountingMetaFetch from '../components/CountingMetaFetch';
import CountingSendImage from '../components/CountingSendImage';
import CountingSendImageWithoutVoiceRecording from '../components/CountingSendImageWithoutVoiceRecording';
import CountingStatsTable from '../components/CountingStatsTable';

class Counting extends React.Component{
	
	constructor(props){
		super(props)
		this.state = {
			canRecordVoice:null,
			isAuthenticated:false,
			isVideoLoading: false,
			Edge_or_Browser:"Browser",
		}
	}

	componentDidMount=()=>{
		this.judgeCanRecordVoice()
	}

	judgeCanRecordVoice=()=>{
		try{
			var m = MediaRecorder
			this.setState({canRecordVoice:true})
		}
		catch(error){
			console.warn(error)
			this.setState({canRecordVoice:false})
		}
	}

	
	switchCamera(Edge_or_Browser){
		this.setState({Edge_or_Browser})
	}


	auhtenticate=(isAuthenticated)=>{
		this.setState({isAuthenticated})
	}


  	render(){  		

		if(!this.state.isAuthenticated){
			return <CountingAuthentication authenticationFunction={this.auhtenticate}/>
		}

		return (
			<div className="content">
				<button
					style={{
						// backgroundColor:this.state.Edge_or_Browser==="Edge"&&"#fff",
						opacity:this.state.Edge_or_Browser==="Edge"&&0.4}} 
						onClick={()=>{this.switchCamera("Browser")}}>
					Browser Camera
				</button>
				<button
					style={{
						// backgroundColor:this.state.Edge_or_Browser==="Browser"&&"#fff",
						opacity:this.state.Edge_or_Browser==="Browser"&&0.4}}
						onClick={()=>{this.switchCamera("Edge")}}>
					Edge Camera
				</button>
				{
					this.state.Edge_or_Browser==="Browser"?
						this.state.canRecordVoice?
							<CountingSendImage/>:<CountingSendImageWithoutVoiceRecording/>
						:<CountingMetaFetch/>
				}
				<CountingStatsTable/>
			</div>
		);
  	}
}

export default Counting;