import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios';
var moment = require('moment');

// import logo from './logo.svg';
// import './App.css';
const API_HOST = process.env.REACT_APP_API_HOST
const url = API_HOST + '/api/'



class VoiceRecording extends React.Component{
	
	constructor(props){
		super(props)
		this.state = {
			recorder:null,
			chunks:[],
			// recordingData:null,
			status:"not recording",
			startRecordingVoice:props.startRecordingVoice
		}
	}
	  
	componentDidMount=()=>{
		//様々なブラウザでマイクへのアクセス権を取得する
		navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia;

		navigator.mediaDevices.getUserMedia({
			audio: true,
			video: false
		})
		.then(stream => this.successFunc(stream))
		.catch(error => errorFunc(error))


		// audioのみtrue。Web Audio APIが問題なく使えるのであれば、第二引数で指定した関数を実行する
		// navigator.getUserMedia({
		// 	audio: true,
		// 	video: false
		// }, this.successFunc, errorFunc);

		// Web Audio APIが使えなかった時
		function errorFunc(error) {
			console.log("error", error);
		}
	}

	successFunc=async(stream)=>{
		let recorder = new MediaRecorder(stream, {
			mimeType: 'video/webm;codecs=vp9'
		});
		let this_ = this

		//集音のイベントを登録する
		recorder.addEventListener('start', function() {
			// await this_.setState({status:"recording"})
			console.log("start__")
			this_.startRecording()
		});
		// recorder.addEventListener('stop', function() {
		// 	// await this_.setState({status:"not recording"})
		// 	console.log("stop__")
		// 	this_.stopRecording()
		// });
		recorder.addEventListener('dataavailable', function(ele) {
			console.log("available")
			if (ele.data.size > 0) {
				this_.state.chunks.push(ele.data);
			}
		});
		this.setState({recorder})
		// this.state.recorder.start()

	}

	componentWillReceiveProps=async(props)=>{
		// console.log("props", props)
		let startRecordingVoice = props.startRecordingVoice
		let finishRecordingEvent = props.finishRecordingEvent
		if(this.state.startRecordingVoice===startRecordingVoice){return}
		if(!finishRecordingEvent){return}
		
		await this.setState({startRecordingVoice})
		if(startRecordingVoice){
			// console.log("startした")
			this.state.recorder.start()
		}
		else{
			console.log("stop()")
			this.state.recorder.stop()
			let v = await this.waitForStopEvent()
			console.log("stop event")
			let audio = document.getElementById('audio');
			audio.dispatchEvent(finishRecordingEvent)
			console.log("カスタムevent発生")
			this.stopRecording()
		}
		// this.state.recorder.start()
		// startRecordingVoice?this.startRecording():this.stopRecording()
	}

	startRecording=()=>{
		// console.log("startRecording")
		this.setState({status:"recording"})
	}

	waitForStopEvent=()=>{
		let this_ = this
		return new Promise(resolve=>{
				const listener = resolve; 
				// this_.state.recorder.addEventListener('dataavailable', listener);
				this_.state.recorder.addEventListener('stop', listener);
		})
	}

	// 録音を止めて、それまでの音声データをCountingに引き渡す
	stopRecording=()=>{
		
		console.log("stopRecording", this.state.chunks)
		let blob = this.state.chunks[0]
		let name = Date.now() + "voice.wav"
		blob.name = name
		this.props.giveVoiceRecord(blob)
		this.setState({chunks:[], startRecordingVoice:false, status:"not recording"})
	}

	render(){  
		return (
		<div className="content">
			{/* <button onClick={()=>this.state.recorder.start()}>3秒録音開始</button>
			<button onClick={()=>this.setState({autoSending:false})}>サーバーに送らない</button> */}
			<p id="audio">{this.state.status}</p>
		</div>
		);
	}
}

export default VoiceRecording;