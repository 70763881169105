import React from "react";
import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST
const url = API_HOST + '/api/'

class Counting extends React.Component{
	
	constructor(props){
		super(props)
		this.state = {
			username:"",
			password:"",
		}
		this.authenticateUser()
	}

	async authenticateUser(){
		let params = {username:this.state.username, password:this.state.password}
		const response = await axios.get(url+'counting_authentication/', {params})
		if(response.status===202){
			this.props.authenticationFunction(true)
		}
	}

  	render(){  
		if(!this.state.authenticated){
			return( <div className="content">
						<input type="text" name="username" id="username" placeholder="username"
							value={this.state.username}
							onChange={e=>this.setState({username:e.target.value})}/>
						<input type="text" name="password" id="password" placeholder="password"
							value={this.state.password}
							onChange={e=>this.setState({password:e.target.value})}/>
						<button onClick={()=>this.authenticateUser()}>OK</button>
					</div>)
		}
  	}
}

export default Counting;