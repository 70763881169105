const info = [
	{
		ID:"Harada",
		schedule:[
			{ time:"9-9.5", plan:"移動", image:"https://demo-bestat-data.s3-ap-northeast-1.amazonaws.com/static/subway.png" },
			{ time:"9.5-11.5", 
				plan:"社内ミーティング", 
				image:"https://demo-bestat-data.s3-ap-northeast-1.amazonaws.com/static/meeting.png",
				memberList:[
				{name:"デイビット・ベッカム",
				 image:"http://livedoor.blogimg.jp/matomille-footbal/imgs/7/3/73843e66.jpg"},
				 {name:"デイビット・ベッカム",
				 image:"http://livedoor.blogimg.jp/matomille-footbal/imgs/7/3/73843e66.jpg"},
				 {name:"デイビット・ベッカム",
				 image:"http://livedoor.blogimg.jp/matomille-footbal/imgs/7/3/73843e66.jpg"},
				 {name:"デイビット・ベッカム",
				 image:"http://livedoor.blogimg.jp/matomille-footbal/imgs/7/3/73843e66.jpg"},
			] },
			{ time:"11.5-12.0", plan:"社内デスクワーク" },
			{ time:"13-14.5", plan:"社内デスクワーク"},
			{ time:"14.5-15", plan:"移動", image:"https://demo-bestat-data.s3-ap-northeast-1.amazonaws.com/static/jr.png" },
			{ time:"15-16", plan:"営業先会議" },
			{ time:"16-16.5", plan:"移動", image:"https://demo-bestat-data.s3-ap-northeast-1.amazonaws.com/static/subway.png" },
			{ time:"16.5-17", plan:"社内デスクワーク"}
		],
		stats:[
			{ type:"カメラ稼働時間", value:"6:32" },
			{ type:"営業件数", value:"4件" },
			// { type: "社内ミーティングの相手", value:[

			// ]},
			{ type:"デスクワーク時間", value:"2:19"}
		],
	},
	{
		ID:"Doi",
		schedule:[
			{ time:"9-10.5", plan:"社内デスクワーク" },
			{ time:"10.5-12.0", 
				plan:"社内ミーティング", 
				image:"https://demo-bestat-data.s3-ap-northeast-1.amazonaws.com/static/meeting.png",
				memberList:[
				{name:"トム・クルーズ",
				 image:"https://rr.img.naver.jp/mig?src=http%3A%2F%2Fwww.officiallyjd.com%2Fwp-content%2Fuploads%2F2012%2F06%2F20120611_hollywood_08.jpg&twidth=1000&theight=0&qlt=80&res_format=jpg&op=r"},
				 {name:"トム・クルーズ",
				 image:"https://rr.img.naver.jp/mig?src=http%3A%2F%2Fwww.officiallyjd.com%2Fwp-content%2Fuploads%2F2012%2F06%2F20120611_hollywood_08.jpg&twidth=1000&theight=0&qlt=80&res_format=jpg&op=r"},
				 {name:"トム・クルーズ",
				 image:"https://rr.img.naver.jp/mig?src=http%3A%2F%2Fwww.officiallyjd.com%2Fwp-content%2Fuploads%2F2012%2F06%2F20120611_hollywood_08.jpg&twidth=1000&theight=0&qlt=80&res_format=jpg&op=r"},
			] },
			{ time:"13-13.5", plan:"移動", image:"https://demo-bestat-data.s3-ap-northeast-1.amazonaws.com/static/jr.png" },
			{ time:"13.5-14.5", plan:"営業先会議"},
			{ time:"14.5-15", plan:"移動", image:"https://demo-bestat-data.s3-ap-northeast-1.amazonaws.com/static/subway.png" },
			{ time:"15-16", 
				plan:"社内ミーティング", 
				image:"https://demo-bestat-data.s3-ap-northeast-1.amazonaws.com/static/meeting.png",
				memberList:[
				{name:"クリスチアーノ・ロナウド",
				 image:"https://kenpiman.com/wp-content/uploads/2018/06/c24e1664986faad7530d72b0efdc0559.jpg"},
				 {name:"クリスチアーノ・ロナウド",
				 image:"https://kenpiman.com/wp-content/uploads/2018/06/c24e1664986faad7530d72b0efdc0559.jpg"},
				 {name:"クリスチアーノ・ロナウド",
				 image:"https://kenpiman.com/wp-content/uploads/2018/06/c24e1664986faad7530d72b0efdc0559.jpg"},
				 {name:"クリスチアーノ・ロナウド",
				 image:"https://kenpiman.com/wp-content/uploads/2018/06/c24e1664986faad7530d72b0efdc0559.jpg"},
			] },
			{ time:"16-17", plan:"社内デスクワーク"}
		],
		stats:[
			{ type:"カメラ稼働時間", value:"5:22" },
			{ type:"営業件数", value:"6件" },
			// { type: "社内ミーティングの相手", value:[

			// ]},
			{ type:"デスクワーク時間", value:"2:44"}
		]
	},
	{
		ID:"Zhang",
		schedule:[
			{ time:"9-10.0", plan:"営業先会議" },
			{ time:"10.0-10.5", plan:"移動", image:"https://demo-bestat-data.s3-ap-northeast-1.amazonaws.com/static/jr.png" },
			{ time:"10.5-12", 
				plan:"社内ミーティング" ,
				image:"https://demo-bestat-data.s3-ap-northeast-1.amazonaws.com/static/meeting.png",
				memberList:[
				{name:"クリスチアーノ・ロナウド",
				 image:"https://kenpiman.com/wp-content/uploads/2018/06/c24e1664986faad7530d72b0efdc0559.jpg"},
				 {name:"クリスチアーノ・ロナウド",
				 image:"https://kenpiman.com/wp-content/uploads/2018/06/c24e1664986faad7530d72b0efdc0559.jpg"},
				 {name:"クリスチアーノ・ロナウド",
				 image:"https://kenpiman.com/wp-content/uploads/2018/06/c24e1664986faad7530d72b0efdc0559.jpg"},
				 {name:"クリスチアーノ・ロナウド",
				 image:"https://kenpiman.com/wp-content/uploads/2018/06/c24e1664986faad7530d72b0efdc0559.jpg"},
			] },
			{ time:"13.0-14.5", plan:"社内デスクワーク"},
			{ time:"14.5-15", plan:"移動", image:"https://demo-bestat-data.s3-ap-northeast-1.amazonaws.com/static/subway.png" },
			{ time:"15-17", plan:"営業先会議"},
		],
		stats:[
			{ type:"カメラ稼働時間", value:"6:08" },
			{ type:"営業件数", value:"6件" },
			{ type:"デスクワーク時間", value:"1:36"}
		]
	}
]

export default info