import React from 'react';
import MaterialTable from "material-table";
import axios from "axios";

const API_HOST = process.env.REACT_APP_API_HOST
const url = API_HOST + '/api/'


class CountingStatsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: []
        };
    }

    componentDidMount=()=>{
        this.getCountingStatsData()
    }

    getCountingStatsData = () => {
        console.log("fetching data...");
        axios
        .get(url+'counting/stats', { params:
            {"days": 7}
        })
        .then(res => this.setState({dataList: res.data.stats}))
        .catch(err => alert(err));
    }

    generateHeader = () => {
        const list = [...Array(9).keys()].map((i, index) => {return {title: (index+10+"時"), field: String(index)}});
        list.push({ title: "日付", field: "date" });
        return(list);
    }

    getRowsData = () => {
        const list = [];
        if(this.state.dataList.length > 0){
            let data = this.state.dataList;
            data.forEach((element) => {
                const tmp = {};
                tmp["date"] = element.date;
                element.stats.map(obj => {
                    tmp[String(obj.hour)] = Math.round(obj.average * 10) / 10;
                });
                list.push(tmp);
            });}
        return(list);
    }

    render(){
        return (
            <div>
                <h2>今週の訪問者数</h2>
                <div className="container" style={{maxWidth: "100%"}}>
                <MaterialTable
                options={{
                    search: false
                }}
                title="Visitors of this week (average)"
                columns={
                    this.generateHeader()
                }
                data={
                    this.getRowsData()
                }
                />
                </div>
            </div>
        );
    }
};

export default CountingStatsTable